import helper from '@/api/helper';
import {
  getInverterDataList,
} from '@/api/Request/inverter';
import chartOptions from '../chart/chart_options';
import request from '../utils';

const heatmapHourly = (res, measureSelect, inverterNameList, steptime) => new Promise(
  (resolve) => {
    const xAxisData = [];

    for (let i = 5; i < 21; i += 1) {
      for (let x = 0; x < 60; x += steptime) {
        let h;
        let m;

        // eslint-disable-next-line no-unused-expressions
        i < 10 ? h = `0${i}` : h = `${i}`;
        // eslint-disable-next-line no-unused-expressions
        x < 10 ? m = `0${x}` : m = `${x}`;
        const time = `${h}:${m}`;

        xAxisData.push(time);
      }
    }

    const yAxisDataLabel = inverterNameList;
    let hourData = [];
    const max = 10;

    if (res.data.success === 'true') {
      Object.keys(res.data.data).forEach((key) => {
        let tmpData = [];

        let val = res.data.data[key][measureSelect.value];

        const {
          INVERTERNAME,
        } = res.data.data[key];

        if (val === undefined) {
          val = 0;
        } else {
          if (typeof val === 'string') {
            val = parseFloat(val);
          }
          val = val.toFixed(1);
        }

        val = parseFloat(val);

        // eslint-disable-next-line radix
        const hour = parseInt(res.data.data[key].Hour);
        // eslint-disable-next-line radix
        const minute = parseInt(res.data.data[key].MinuteGrup);

        let h;
        let m;
        // eslint-disable-next-line no-unused-expressions
        hour < 10 ? h = `0${hour}` : h = `${hour}`;
        // eslint-disable-next-line no-unused-expressions
        minute < 10 ? m = `0${minute}` : m = `${minute}`;
        const time = `${h}:${m}`;

        tmpData = [INVERTERNAME, time, val];
        hourData.push(tmpData);
      });
    }

    hourData = hourData.map((item) => [item[1], item[0], item[2] || '-']);
    let name = measureSelect.text;
    if (measureSelect.unit !== '') {
      name = `${measureSelect.text} (${measureSelect.unit})`;
    }
    const min = 0;
    const series = [{
      name,
      type: 'heatmap',
      data: hourData,
      label: {
        show: false,
        fontSize: 10,
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    }];

    const grid = {
      height: '80%',
      top: '5%',
      width: '70%',
      right: '6%',

    };
    const tooltip = {
      position: 'right',
    };

    const opt = chartOptions.echartHeatmap_Options(
      series,
      xAxisData,
      yAxisDataLabel,
      min,
      max,
      grid,
      tooltip,
    );

    resolve(opt);
  },
);

const heatmapStatusx = (formdata, inverterInfo) => new Promise((resolve) => {
  request.Api('/api/inverter/status', formdata, 'post').then((res) => {
    const _status_list = {};

    Object.keys(res.data.data).forEach((key) => {
      const {
        santral,
      } = res.data.data[key];

      if (!_status_list.hasOwnProperty(santral)) {
        _status_list[santral] = [];
      }
      if (!_status_list[santral].hasOwnProperty(res.data.data[key].INVERTER)) {
        _status_list[santral][res.data.data[key].INVERTER] = res.data.data[key];
      }
    });
    console.log(_status_list);

    const opt = {};

    Object.keys(inverterInfo).forEach((item) => {
      let hourData = [];
      const xAxisData = ['s'];
      const yAxisDatas_label = Object.keys(inverterInfo[item].INVERTER);

      console.log(_status_list[item]);
      Object.keys(inverterInfo[item].INVERTER).forEach((k) => {
        let status = 0;

        const inv = inverterInfo[item].INVERTER[k];

        if (!_status_list.hasOwnProperty(item)) {
          _status_list[item] = [];
        }
        if (!_status_list[item].hasOwnProperty(inv.INVERTER)) {
          status = 0;
        } else {
          status = 1;
        }
        console.log(item);
        console.log(inv.INVERTER);
        console.log(_status_list[item][inv.INVERTER]);

        let tmpData = [];
        tmpData = [inv.INVERTER, 's', status];
        hourData.push(tmpData);
      });

      hourData = hourData.map((item) => [item[1], item[0], item[2] || '-']);

      const min = 0;
      const series = [{
        name: '',
        type: 'heatmap',
        data: hourData,
        label: {
          show: true,
          fontSize: 10,
          color: 'black',
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      }];

      const grid = {
        height: '85%',
        top: '5%',
        width: '70%',
        right: '6%',

      };
      const tooltip = {
        position: 'right',
      };

      opt[item] = chartOptions.echartHeatmap_Options(
        series,
        xAxisData,
        yAxisDatas_label,
        0,
        1,
        grid,
        tooltip,
        false,
      );
    });

    /*   Object.keys(inverterInfo).forEach((item) => {
        let xAxisData = ['status'];
        let yAxisDatas_label = Object.keys(inverterInfo[item].INVERTER);
        let hourData = [];

        Object.keys(res.data.data).forEach((key) => {
          Object.keys(res.data.data[key]).forEach((k) => {
            let tmpData = [];

            let s = parseInt(k);
            let h;
            let val = res.data.data[key][k][measureSelect];

            if (val == undefined) {
              val = 0;
            } else {
              val = val.toFixed(1);
            }

            val = parseFloat(val);

            if (max < val) {
              max = val;
            }

            let t_val = "";
            if (val > 0) {
              t_val = val
            }

            s < 10 ? (h = `0${s}:00`) : (h = `${s}:00`);
            tmpData = [inverterList[key].INVERTERNAME, h, t_val];
            hourData.push(tmpData)
          });
        });

        hourData = hourData.map(function (item) {
          return [item[1], item[0], item[2] || "-"];
        });

        let min = 0;
        let series = [{
          name: i18n.t(measureSelect),
          type: "heatmap",
          data: hourData,
          label: {
            show: true,
            fontSize: 10,
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        }, ];

        let grid = {
          height: '85%',
          top: '5%',
          width: '70%',
          right: "6%"

        };
        let tooltip = {
          position: "right",
        };

        let opt = chartOptions.echartHeatmap_Options(
          series,
          xAxisData,
          yAxisDatas_label,
          min,
          max,
          grid,
          tooltip
        );
      }); */
    console.log(opt);
    resolve(opt);
  });
});

const heatmapStatuss = (formdata, inverterInfo) => new Promise((resolve) => {
  request.Api('/api/inverter/status', formdata, 'post').then((res) => {
    const _status_list = {};

    Object.keys(res.data.data).forEach((key) => {
      const {
        santral,
      } = res.data.data[key];

      if (!_status_list.hasOwnProperty(santral)) {
        _status_list[santral] = {};
      }
      if (!_status_list[santral].hasOwnProperty(res.data.data[key].INVERTER)) {
        _status_list[santral][res.data.data[key].INVERTER] = res.data.data[key];
      }
    });

    Object.keys(inverterInfo).forEach((item) => {
      Object.keys(inverterInfo[item].INVERTER).forEach((k) => {
        const inv = inverterInfo[item].INVERTER[k];

        if (!_status_list.hasOwnProperty(item)) {
          _status_list[item] = {};
        }

        if (!_status_list[item].hasOwnProperty(inv.INVERTER)) {
          _status_list[item][inv.INVERTER] = inverterInfo[item].INVERTER[inv
            .INVERTER];
          _status_list[item][inv.INVERTER].status = 0;
        } else {
          _status_list[item][inv.INVERTER].status = 1;
        }
        _status_list[item][inv.INVERTER].installedDcPower = inverterInfo[item]
          .INVERTER[inv
            .INVERTER].installedDcPower;
      });
    });

    resolve(_status_list);
  });
});

const heatmapStatus = (formdata, inverterInfo) => new Promise((resolve) => {
  request.Api('/api/inverter/status', formdata, 'post').then((res) => {
    const _status_list = {};
    Object.keys(res.data.data).forEach((key) => {
      if (!_status_list.hasOwnProperty(res.data.data[key].santral)) {
        _status_list[res.data.data[key].santral] = [];
      }
      _status_list[res.data.data[key].santral].push(res.data.data[key]);
    });
    resolve(_status_list);
  });
});

// eslint-disable-next-line camelcase
const heatmapWeeklyHourMeans_inverter_diffs = (formdata, measureSelect) => new Promise((
  resolve,
) => {
  const hourData = [];
  let max = 10;

  request.Api('/api/inverter/list', formdata, 'post').then((res) => {
    if (res.data.success === 'true') {
      const tmpGroupData = {};
      const totalGroupData = {};
      console.log(res.data.data);
      Object.keys(res.data.data).forEach((key) => {
        const hour = parseInt(res.data.data[key].Hour);

        if (hour > 5 && hour < 20) {
          const minute = parseInt(res.data.data[key].MinuteGrup);

          let h;
          let m;
          hour < 10 ? h = `0${hour}` : h = `${hour}`;
          minute < 10 ? m = `0${minute}` : m = `${minute}`;

          const time = `${h}:${m}`;

          if (tmpGroupData[time] === undefined) {
            tmpGroupData[time] = [];
            totalGroupData[time] = {
              count: 0,
              index: 0,
            };
          }

          let val = res.data.data[key][measureSelect];
          val = helper.show_float_val(val);

          if (max < val) {
            max = val;
          }

          if (val > 0) {
            totalGroupData[time].count += val;
            totalGroupData[time].index += 1;
          }

          tmpGroupData[time].push(res.data.data[key]);
        }
      });

      Object.keys(tmpGroupData).forEach((k) => {
        if (totalGroupData[k].count > 0 && totalGroupData[k].index > 0) {
          totalGroupData[k].mean = totalGroupData[k].count / totalGroupData[k].index;
        }

        Object.keys(tmpGroupData[k]).forEach((i) => {
          const val = tmpGroupData[k][i][measureSelect];
          const f = totalGroupData[k].mean * (90 / 100);

          const colors = ['#23a643', '#f03a2e'];

          const obj = {};
          if (f > val) {
            // eslint-disable-next-line prefer-destructuring
            obj.color = colors[1];
          } else {
            // eslint-disable-next-line prefer-destructuring
            obj.color = colors[0];
          }

          obj.INVERTERNAME = tmpGroupData[k][i].INVERTERNAME;
          obj.value = val;
          obj.time = k;
          obj.mean = totalGroupData[k].mean;

          if (f > 0) {
            obj.meanval = f;
          } else {
            obj.meanval = 0;
          }

          hourData.push(obj);
        });
      });

      resolve(hourData);
    }
  });
});

const emptyDeviceMinuteDatas = (inverterList, measureSelect) => {
  const hour = 5;
  const minute = 2;
  const stepTime = 5;
  const obj = {};
  for (let i = hour; i <= 20; i += 1) {
    for (let x = minute; x < 60; x += stepTime) {
      let h;
      let m;
      i < 10 ? h = `0${i}` : h = `${i}`;
      x < 10 ? m = `0${x}` : m = `${x}`;
      const time = `${h}:${m}`;

      obj[time] = [];
      Object.keys(inverterList).forEach((key) => {
        // obj[time].push({
        const val = {};
        val[inverterList[key]] = {};
        val[inverterList[key]][measureSelect] = 0;
        obj[time].push(val);
      });
    }
  }
  console.log(obj);
};

const emptyDeviceMinuteData = (inverterList, measureSelect) => {
  const hour = 5;
  const minute = 0;
  const stepTime = 10;
  const obj = {};
  for (let i = hour; i <= 20; i += 1) {
    for (let x = minute; x < 60; x += stepTime) {
      let h;
      let m;
      // eslint-disable-next-line no-unused-expressions
      i < 10 ? h = `0${i}` : h = `${i}`;
      // eslint-disable-next-line no-unused-expressions
      x < 10 ? m = `0${x}` : m = `${x}`;
      const time = `${h}:${m}`;

      obj[time] = [];
      Object.keys(inverterList).forEach((key) => {
        // obj[time].push({
        obj[time][inverterList[key]] = {};
        obj[time][inverterList[key]][measureSelect] = 0;
      });
    }
  }

  return obj;
};

const heatmapWeeklyHourMeans_inverter_diff1 = (formdata, inverterList, measureSelect) => new Promise((resolve) => {
  const hourData = [];
  let max = 10;

  getInverterDataList(formdata).then((res) => {
    if (helper.resIsEmpty(res)) {
      const tmpGroupData = {};
      const totalGroupData = {};
      Object.keys(res.data.data).forEach((key) => {
        const hour = parseInt(res.data.data[key].Hour);

        if (hour > 5 && hour < 21) {
          const minute = parseInt(res.data.data[key].MinuteGrup);

          let h;
          let m;
          // eslint-disable-next-line no-unused-expressions
          hour < 10 ? h = `0${hour}` : h = `${hour}`;
          // eslint-disable-next-line no-unused-expressions
          minute < 10 ? m = `0${minute}` : m = `${minute}`;

          const time = `${h}:${m}`;

          if (tmpGroupData[time] === undefined) {
            tmpGroupData[time] = [];
            totalGroupData[time] = {
              count: 0,
              index: 0,
            };
          }

          let val = res.data.data[key][measureSelect];
          val = helper.show_float_val(val);

          if (max < val) {
            max = val;
          }

          if (val > 0) {
            totalGroupData[time].count += val;
            totalGroupData[time].index += 1;
          }

          tmpGroupData[time].push(res.data.data[key]);
        }
      });

      const colors = ['#23a643', '#f03a2e', '#FF8F00'];
      const hour = 5;
      const minute = 0;
      const stepTime = 10;

      for (let i = hour; i < 21; i += 1) {
        for (let x = minute; x < 60; x += stepTime) {
          const h = i < 10 ? `0${i}` : `${i}`;
          const m = x < 10 ? `0${x}` : `${x}`;
          const time = `${h}:${m}`;

          if (totalGroupData[time]) {
            if (totalGroupData[time].count > 0 && totalGroupData[time].index > 0) {
              totalGroupData[time].mean = totalGroupData[time].count / totalGroupData[time]
                .index;
            }
          } else {
            totalGroupData[time] = {
              count: 0,
              index: 0,
              mean: 0,
            };
          }

          if (!tmpGroupData[time]) {
            tmpGroupData[time] = {};
          }

          let val = 0;
          // eslint-disable-next-line no-restricted-syntax, no-loop-func
          Object.keys(inverterList).forEach((inv) => {
            if (tmpGroupData[time][inv]) {
              emptyData[time][inv] = tmpGroupData[time][inv];
            }
            val = emptyData[time][inv][measureSelect];

            const f = totalGroupData[time].mean * (90 / 100);
            const obj = {};

            if (f > val) {
              obj.color = val === 0 ? colors[1] : colors[2];
            } else if (f === 0) {
              // eslint-disable-next-line prefer-destructuring
              obj.color = colors[1];
            } else {
              // eslint-disable-next-line prefer-destructuring
              obj.color = colors[0];
            }

            obj.INVERTERNAME = inverterList[inv].INVERTERNAME;
            obj.value = val;
            obj.time = time;
            obj.mean = totalGroupData[time].mean;
            obj.meanval = f > 0 ? f : 0;

            hourData.push(obj);
          });
        }
      }

      resolve(hourData);
    }
  });
});

const heatmapWeeklyHourMeans_inverter_diff = (formdata, inverterList, measureSelect) => new Promise(
  (resolve) => {
    const hourData = [];
    let max = 10;

    const invList = Object.keys(inverterList);
    console.log(invList);
    const emptyData = emptyDeviceMinuteData(invList, measureSelect);
    console.log(emptyData);
    getInverterDataList(formdata).then((res) => {
      if (res.data.success === 'true') {
        const tmpGroupData = {};
        const totalGroupData = {};
        Object.keys(res.data.data).forEach((key) => {
          const hour = parseInt(res.data.data[key].Hour);
          const inv = res.data.data[key].INVERTER;
          if (hour > 4 && hour < 20) {
            const minute = parseInt(res.data.data[key].MinuteGrup);

            let h;
            let m;
            // eslint-disable-next-line no-unused-expressions
            hour < 10 ? h = `0${hour}` : h = `${hour}`;
            // eslint-disable-next-line no-unused-expressions
            minute < 10 ? m = `0${minute}` : m = `${minute}`;

            const time = `${h}:${m}`;

            if (tmpGroupData[time] === undefined) {
              tmpGroupData[time] = {};
              totalGroupData[time] = {
                count: 0,
                index: 0,
              };
            }

            let val = res.data.data[key][measureSelect];
            val = helper.show_float_val(val);

            if (max < val) {
              max = val;
            }

            if (val > 0) {
              totalGroupData[time].count += val;
              totalGroupData[time].index += 1;
            }

            tmpGroupData[time][inv] = res.data.data[key];
          }
        });
        const colors = ['#23a643', '#f03a2e', '#FF8F00'];
        const hour = 5;
        const minute = 0;
        const stepTime = 10;

        for (let i = hour; i < 21; i += 1) {
          for (let x = minute; x < 60; x += stepTime) {
            let h;
            let m;
            // eslint-disable-next-line no-unused-expressions
            i < 10 ? h = `0${i}` : h = `${i}`;
            // eslint-disable-next-line no-unused-expressions
            x < 10 ? m = `0${x}` : m = `${x}`;
            const time = `${h}:${m}`;

            if (totalGroupData[time] !== undefined) {
              if (totalGroupData[time].count > 0 && totalGroupData[time].index > 0) {
                totalGroupData[time].mean = totalGroupData[time].count / totalGroupData[
                  time].index;
              }
            } else {
              totalGroupData[time] = {
                count: 0,
                index: 0,
                mean: 0,
              };
            }

            if (tmpGroupData[time] === undefined) {
              tmpGroupData[time] = {};
            }

            Object.keys(tmpGroupData[time]).forEach((inv) => {
              if (tmpGroupData[time][inv] !== undefined) {
                emptyData[time][inv] = tmpGroupData[time][inv];
              }

              const val = emptyData[time][inv][measureSelect];
              const f = totalGroupData[time].mean * (90 / 100);

              const obj = {};
              if (f > val) {
                if (val === 0) {
                  // eslint-disable-next-line prefer-destructuring
                  obj.color = colors[1];
                } else {
                  // eslint-disable-next-line prefer-destructuring
                  obj.color = colors[2];
                }
              } else if (f === 0) {
                // eslint-disable-next-line prefer-destructuring
                obj.color = colors[1];
              } else {
                // eslint-disable-next-line prefer-destructuring
                obj.color = colors[0];
              }
              let name = '';
              if ('INVERTERNAME' in emptyData[time][inv]) {
                name = emptyData[time][inv].INVERTERNAME;
              } else {
                name = inverterList[inv].INVERTERNAME;
              }
              obj.INVERTERNAME = name;
              obj.value = val;
              obj.time = time;
              obj.mean = totalGroupData[time].mean;

              if (f > 0) {
                obj.meanval = f;
              } else {
                obj.meanval = 0;
              }

              hourData.push(obj);
            });
          }
        }

        resolve(hourData);
      }
    });
  },
);

const inverterMinuteDataHeatmap = (formData, inverterList, measureSelect) => new Promise((
  resolve,
) => {
  const hourData = [];
  let max = 10;
  const invList = Object.keys(inverterList);
  const emptyData = emptyDeviceMinuteData(invList, measureSelect);

  const processHourData = (res) => {
    const tmpGroupData = {};
    const totalGroupData = {};

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in res.data.data) {
      // eslint-disable-next-line radix
      const hour = parseInt(res.data.data[key].Hour);
      const inv = res.data.data[key].INVERTER;
      if (hour > 4 && hour < 20) {
        // eslint-disable-next-line radix
        const minute = parseInt(res.data.data[key].MinuteGrup);
        const h = hour < 10 ? `0${hour}` : `${hour}`;
        const m = minute < 10 ? `0${minute}` : `${minute}`;
        const time = `${h}:${m}`;

        if (!tmpGroupData[time]) {
          tmpGroupData[time] = {};
          totalGroupData[time] = {
            count: 0,
            index: 0,
          };
        }

        let val = res.data.data[key][measureSelect];
        val = helper.show_float_val(val);

        if (max < val) {
          max = val;
        }

        if (val > 0) {
          totalGroupData[time].count += val;
          totalGroupData[time].index += 1;
        }

        tmpGroupData[time][inv] = res.data.data[key];
      }
    }

    const colors = ['#23a643', '#f03a2e', '#FF8F00'];
    const hour = 5;
    const minute = 0;
    const stepTime = 10;

    for (let i = hour; i < 21; i += 1) {
      for (let x = minute; x < 60; x += stepTime) {
        const h = i < 10 ? `0${i}` : `${i}`;
        const m = x < 10 ? `0${x}` : `${x}`;
        const time = `${h}:${m}`;

        if (totalGroupData[time]) {
          if (totalGroupData[time].count > 0 && totalGroupData[time].index > 0) {
            totalGroupData[time].mean = totalGroupData[time].count / totalGroupData[time]
              .index;
          }
        } else {
          totalGroupData[time] = {
            count: 0,
            index: 0,
            mean: 0,
          };
        }

        if (!tmpGroupData[time]) {
          tmpGroupData[time] = {};
        }

        let val = 0;

        // eslint-disable-next-line no-restricted-syntax
        Object.keys(emptyData[time]).forEach((inv) => {
          if (tmpGroupData[time][inv]) {
            emptyData[time][inv] = tmpGroupData[time][inv];
          }
          val = emptyData[time][inv][measureSelect];

          const f = totalGroupData[time].mean * (90 / 100);
          const obj = {};

          if (f > val) {
            obj.color = val === 0 ? colors[1] : colors[2];
          } else if (f === 0) {
            // eslint-disable-next-line prefer-destructuring
            obj.color = colors[1];
          } else {
            // eslint-disable-next-line prefer-destructuring
            obj.color = colors[0];
          }

          obj.INVERTERNAME = inverterList[inv].INVERTERNAME;
          obj.value = val;
          obj.time = time;
          obj.mean = totalGroupData[time].mean;
          obj.meanval = f > 0 ? f : 0;

          hourData.push(obj);
        });
      }
    }

    resolve(hourData);
  };

  getInverterDataList(formData).then((res) => {

    if (helper.resIsEmpty(res)) {
      processHourData(res);
    }
  });
});

export default {
  heatmapHourly,
  heatmapStatus,
  heatmapWeeklyHourMeans_inverter_diff,
  inverterMinuteDataHeatmap,

};
